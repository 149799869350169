import * as React from 'react'
import * as styles from "./homelayout.module.css"
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const HomeLayout = ({ children }) => {
    if (typeof window !== `undefined`) {
        document.body.style.backgroundColor = "rgb(14, 14, 14)";
        document.body.style.color = "white";
    }

    return (
        <div>
            <div className={styles.home}>
                {children}
            </div>
            <div className={styles.copyright}>
                <p>APPERCASED, WatchNews SDK, Watch+, Snapcards Copyright © 2023 Krzysztof Rózga Mobile Apps | <a href={"/privacy"}>Privacy Policy</a></p>
            </div>
        </div>
    )
}

export default HomeLayout