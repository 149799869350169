import * as React from 'react'

const SEO = ({pageTitle, pageDescription, children }) => {
    return (
        <>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            {children}
        </>
    )
}

export default SEO