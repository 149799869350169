import * as React from "react"
import HomeLayout from "../components/homelayout"
import SEO from "../components/seo"

// markup
const NotFoundPage = () => {
  return (
    <SEO pageTitle="Not Found | APPERCASED" pageDescription="404 - Not Found">
      <HomeLayout>
        <div style={{height: "100vh", padding: "50px"}}>
          <div >
            <h1>Page not found</h1>
            <h2>404</h2>
            <a href="/">Go to home page</a>
          </div>
        </div>
      </HomeLayout>
    </SEO>
  )
}

export default NotFoundPage
